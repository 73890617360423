/* Global Styles */
body {
  font-family: 'Montserrat', sans-serif;
  background-color: #1c1c1e;
  color: white;
  margin: 0;
  padding: 0;
}

/* Search Section */
.search-section {
  text-align: center;
  padding: 50px 20px;
  background-color: #2b2b34;
}

h1 {
  font-size: 2.5rem;
  color: #f5a623;
}

.search-bar {
  margin-top: 20px;
  display: inline-block;
}

input {
  padding: 15px;
  width: 300px;
  border-radius: 25px;
  border: none;
  outline: none;
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.1);
  color: white;
}

button {
  margin-left: 10px;
  padding: 15px 25px;
  background-color: #f5a623;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1rem;
  color: white;
}

button:disabled {
  background-color: #888;
}

.error-message {
  margin-top: 15px;
  color: #f95d6a;
}

/* Movies Grid */
.movies-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 30px;
  padding: 50px;
  max-width: 1200px;
  margin: 0 auto;
}

.movie-card {
  background-color: #252529;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.movie-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
}

.movie-card img {
  width: 100%;
  height: 350px;
  object-fit: cover;
}

.movie-info {
  padding: 15px;
}

.movie-info h3 {
  margin: 10px 0;
  font-size: 1.25rem;
}

.movie-info p {
  margin: 0;
  color: #888;
}

/* Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #2b2b34;
  padding: 30px;
  border-radius: 10px;
  max-width: 500px;
  text-align: center;
  position: relative;
}

.modal-content img {
  width: 100%;
  border-radius: 10px;
}

.modal-content h2 {
  margin: 20px 0;
  color: #f5a623;
}

.close-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #f5a623;
  border: none;
  border-radius: 25px;
  color: white;
  cursor: pointer;
}
